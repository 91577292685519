export type InstallationConfiguration = {
    minInstallationDate: number
    maxInstallationDate: number
}

export type OptionConfiguration = {
    labelKey: string
    value: string
    preselected: boolean
}

export type ShowPortability = {
    identifier: string
    wishValue: string
}

export type PortabilityConfiguration = {
    contractHolderMaximumNumber: number
    portabilityMaximumNumber: number
    previousProvider: boolean
    previousContractHolder: boolean
    previousContractAddress: boolean
    previousContractTermination: boolean
    showPortability: ShowPortability
    B2BpreviousContractTerminationLink: string
    B2CpreviousContractTerminationLink: string
}

export enum ConfigurationDisplayType {
    ONE_LINE = 'oneLine',
    MULTI_LINE = 'multiLine',
    FREE = 'free',
}

export enum ConfigurationType {
    CHECKBOXES = 'checkboxes',
    RADIO_BUTTONS = 'radioButtons',
    CONDITIONAL = 'conditional',
}

export type ConfigurationLink = {
    text: string
    url: string
}

export type JunctionBoxOption = {
    title: string
    subtitle: string
    badgeIdentifier?: string
    information: string
    datasheet?: string
}

export type HouseConnectionOption = {
    title: string
    badgeIdentifier?: string
    information: string
}

export type OpticFiberOption = {
    title: string
    badgeIdentifier?: string
    information: string
}

export type ConfigurationJunctionBox = {
    identifier: string
    title: string
    preselected: number
    required: boolean
    options: JunctionBoxOption[]
}

export type ConfigurationHouseConnection = {
    identifier: string
    title: string
    preselected: number
    options: HouseConnectionOption[]
}

export type ConfigurationOpticFiber = {
    identifier: string
    title: string
    preselected: number
    options: OpticFiberOption[]
}

export type ConfigurationInstallationServiceOption = {
    title: string
    subtitle: string
    cost: number
    information: string
}

export type ConfigurationInstallationService = {
    title: string
    subtitle: string
    video: string
    option: ConfigurationInstallationServiceOption
}

export type ConfigurationInstallationInformation = {
    title: string
    subtitle: string
}

export type ConfigurationAppartmentData = {
    floorInformation: string
    floorInformationRequired: boolean
    floorInformationRequiredIf: string
    flatPosition: string
    flatPositionRequired: boolean
    flatPositionRequiredIf: string
    flatNumber: string
    flatNumberRequired: boolean
    flatNumberRequiredIf: string
}

export type InstallationDetailsConfiguration = {
    appartmentData?: ConfigurationAppartmentData
    junctionBox: ConfigurationJunctionBox
    houseConnection?: ConfigurationHouseConnection
    opticFiber?: ConfigurationOpticFiber
    installationService: ConfigurationInstallationService
    installationInformation?: ConfigurationInstallationInformation
}

export type ConditionConfiguration = {
    identifier: string
    value: string[]
}

export type ConfigurationProductCategoryDependencies = {
    excluded: string[]
    required: string[]
}

export type ProductCategoryConfiguration = {
    id: string
    config: ConfigurationProductCategoryDependencies
}

export type TelephoneConfiguration = {
    identifier: string
    link?: ConfigurationLink
    helpText?: string
    required?: boolean
    type: ConfigurationType
    displayType?: ConfigurationDisplayType
    options?: OptionConfiguration[]
    condition?: ConditionConfiguration
    render?: TelephoneConfiguration[]
    noMarginBottom?: boolean
    marginTop?: string | number
    titleHidden?: boolean
}

export type TelephoneOptions = {
    portabilityVisible: boolean
    telephoneConfiguration: TelephoneConfiguration[]
}

export type NoProductAvailable = {
    haveLink: boolean
    link: string
    redirectURL: string
    redirectNewTab: boolean
}

export type VZFConfiguration = {
    optionalFees: { name: string; value: number }[]
    footNoteText: { name: string; page: number }[]
    introText: string[]
}

export type TransferFeeOption = {
    feeId: string
    visibleB2B: boolean
}

export type InvoiceFeeOption = {
    feeId: string
    visibleB2B: boolean
}

export type BankDetailsConfiguration = {
    consentLabel: {
        selfAccountHolder: string
        differentAccountHolder: string
    }
    useSecondConsentIBAN?: boolean
    useInvoiceFee?: InvoiceFeeOption
    useTransferFee?: TransferFeeOption
    displayIBAN: boolean
    displayTransfer: boolean
    displayDayOfTransfer: boolean
    preselected?: string
    invoiceSend: {
        display: boolean
        enableOptionMail: boolean
        enableOptionEmail: boolean
    }
}

export type OrderOverviewCheckboxCategory = {
    header: string
    subheader?: string
    helperText?: string
    checkboxes: string[]
}

export type OrderOverviewCheckboxCategoryForLegalTerms = {
    header: string
    subheader?: string
    helperText?: string
    checkboxes: { value: string; isDisplayedInB2B: boolean }[]
}

export type OrderOverviewConfiguration = {
    vzf: OrderOverviewCheckboxCategory
    legalTerms: OrderOverviewCheckboxCategoryForLegalTerms
    newsletters?: OrderOverviewCheckboxCategory
    sendOverView?: OrderOverviewCheckboxCategory
    showPeriodOfNoticeInfo: boolean
    showRightOfWithdrawalInfo: boolean
}

export type ContactDataViewConfiguration = {
    activateSalesPartnerEmail: boolean
    salesPartnerEmail: string
    enableCompanyTaxID: boolean
    enableLegalForm: boolean
    enableCompanyRegisterEntry: boolean
    enableCompanyLocation: boolean
    enableAlternativeLegalForm: boolean
}

export type VoucherConfiguration = {
    enableAutomatic: boolean
    enableManual: boolean
}

export interface FooterLink {
    i18Key: string
    link: string
}

export interface Icons {
    header: string
    footer: string
}

export type GlobalConfiguration = {
    title: string
    showBasketInfoIcons: boolean
    enableWelcomePage: boolean
    useCustomCombiDefaultSelection: boolean
    enablePresentation: boolean
    enableHardwareSelection: string[]
    logoClickWebsite: string
    footer: FooterLink[]
    showFooterLogo: boolean
    icons: Icons
}

export type TouchPointEntry = {
    label: string
    hasInput: boolean
}

export type OrderConfirmationConfiguratiuon = {
    enableTouchPoints: boolean
    enableAdditionalLinks: boolean
    linkDestination1: string
    linkDestination2: string
    linkDestination3: string
    touchPoints: TouchPointEntry[]
}

export type Customize = {
    globalConfiguration: GlobalConfiguration
    voucherConfiguration: VoucherConfiguration
    portabilityConfiguration: PortabilityConfiguration
    productCategories: ProductCategoryConfiguration[]
    telephoneOptions: TelephoneOptions
    installationConfiguration: InstallationConfiguration
    installationDetailsConfiguration: InstallationDetailsConfiguration
    bankDetails: BankDetailsConfiguration
    orderOverview: OrderOverviewConfiguration
    noProductAvailable: NoProductAvailable
    vzfConfiguration: VZFConfiguration
    contactDataViewConfiguration: ContactDataViewConfiguration
    orderConfirmationConfiguratiuon: OrderConfirmationConfiguratiuon
    optList: string[]
}
